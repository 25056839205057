import React, {useEffect,useState,useRef} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
 
import AddIcon from '@material-ui/icons/Add';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
 
import {  Button } from 'gatsby-theme-material-ui';
 
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Fab from '@material-ui/core/Fab';
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
 
 
import { MenuItem, Menu, Avatar, Container, CardMedia, CircularProgress } from '@material-ui/core';
import { DotsVertical } from 'mdi-material-ui';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
 
import Adds from '../components/Adds';
 
import SharedPosts from "../components/shared_posts";
import queryString from 'query-string';
 
import { red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
 
 
const drawerWidth = 300;
const drawerWidthRight = 240;
globalThis.LOADING_IMAGE =  require('../images/loading.gif');
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
 
  toolbar: { 
      paddingBottom: '10px',
      color: 'white'
  },
  toolbar2:{
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarClipped: {
    zIndex: theme.zIndex.drawer + 1,
    marginTop: '15px' ,
    height: '50px', 
    background: orange[500]
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerRight: {
    width: drawerWidthRight,
    flexShrink: 0,
  },
  drawerPaperRight: {
    width: drawerWidthRight,
  },
  headerSpacer: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 50,
    ...theme.mixins.toolbar,
     
  },
  headerSpacer2: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 60,
  },
  headerSpacer3: {
    display: 'fixed',
    alignItems: 'center',
    paddingTop: 75,
    paddingRight: 5,
    paddingLeft: 5,
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    maxWidth: "auto",
    zIndex: 0,
    overflow: 'auto',
  },
  content2: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
   
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    maxWidth: "100%",
    zIndex: 0,
    overflow: 'auto',
  },
  contentShift2: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1,
    marginLeft: 0,
    zIndex: 0,
    overflow: 'auto',
  },
  typography: {
    useNextVariants: true,
  },  
  topWhiteBar:{
    height: '15px',
    width: '100%',
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex:  theme.zIndex.drawer + 1 
  },
  fab: {
    margin: '0px',
    top: 'auto',
    right: '20px',
    bottom: '20px',
    left: 'auto',
    position: 'fixed',
    backgroundColor: green[300],
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: '20px',
    left: '55px',
    position: 'fixed',
    backgroundColor: 'transparent',
    background: 'transparent',
   
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap2: {
    margin: theme.spacing(1),
    top: '110px',
    right: 'auto',
    bottom: 'auto',
    left: '90px',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1 
  },
  fabMap3: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: '20px',
    left: '55px',
    position: 'fixed',
   
    zIndex: theme.zIndex.drawer + 1 
  },
  footer: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    background: orange[500]
  },
 
  footerDialog: {
    display: 'flex',
    height: '50px',
    width: '100%',
    left: 0,
    position: 'fixed',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1 
  },
  loginButton: {
    color: 'white',
    textDecoration: 'none',
  },
  menuButton2: {
    color: 'white',
    textTransform: 'none',
  },
  dotsVerticalIcon: {
    color: "#efefef"
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: red[500],
  },
  adds:{
    width: '160',
    height: '600',
    columnCount: 1
  },
  linkButton:{
    color: 'blue',
  },
  avatar: {
    backgroundColor: red[500],
  }
  
}));

let openDrawer  = true;
let openMapDialog = false;

 
var d = new Date();
var m = d.getMonth() + 1;
var today = d.getFullYear()+'-'+ 
    (m<10? ('0'+m):m)+'-'+
    (d.getDate()<10? ('0'+d.getDate()):d.getDate())
 
 
const SharedIndex = (props) => {
  
    const theme = useTheme();
    const classes = useStyles();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const [isFullScreen, setIsFullScreen] = useState(false);
     
   // const params = queryString.parse( props.location.search);
    const avatarRef = useRef(null);
    
  
    const [urlLocation, setUrlLocation] = useState(props.location);
 
    const [open, setOpen] = React.useState(openDrawer);
    
    const [openPost, setOpenPost] = React.useState(false);
    
  
    const [toggleLogin, setToggleLogin] = React.useState(false);
    
    
    const handleDrawer = () =>{
      openDrawer = !openDrawer;
      setOpen(openDrawer)
    }

    

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElAvatar, setAnchorElAvatar] = React.useState<null | HTMLElement>(null);


    const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClickAvatar = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElAvatar(event.currentTarget);
    };
 
  
    const handleCloseMobile = () => {
      setAnchorEl(null);
    };
    const handleCloseAvatar = () => {
      setAnchorElAvatar(null);
    };
  
   

  const handleShowHome = (url) =>{
    navigate(`/?fid=post&where=postType`);
  }

  const handleWelcomePage = () =>{
    navigate("/?mid=welcome");
  }
 

  const handleHelpPage = () =>{
   navigate("/?mid=help");
  }

  const handleContactPage = () =>{
    navigate("/?mid=contactus");
  }
 
  const handleLogin=(status)=>{
    if(status){
       navigate("/?login=now");
    }
  }

  const handleOpenPostDialog = (dialogBox: string ) =>{
    navigate("/?login=now");
  }

 
  useEffect( ()=>{
    console.log("matchesMD matchesSM matchesXS 2: " + matchesMD + " : "  +matchesSM + " : " + matchesXS)
    if(!matchesMD&&!matchesSM&&matchesXS) {
      setIsFullScreen(true);
    } else{
      setIsFullScreen(false);
    }
  },[matchesMD,matchesSM,matchesXS])

  useEffect(() => {
      
    console.log("showPage location change: " +  globalThis.SHOWPAGE);
    const params = queryString.parse( props.location.search);
    setUrlLocation(props.location);

  },[props.location]);

    const data = useStaticQuery(graphql`
          query{
              site{
                  siteMetadata{
                      title
                      contact {
                        email
                        phone
                      }
                  }
              }
          }
      `)
    
   
    return (
   
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.topWhiteBar}></div>
       
        <AppBar
          position="fixed"
          className={classes.appBarClipped} 
        >
          <Toolbar className={classes.toolbar}>
        
            <Button onClick={handleDrawer}  >
                    <MenuIcon  className={classes.menuButton2}  />
            </Button>

            <div>
              <Typography variant="h6" noWrap>
                
                {data.site.siteMetadata.title} {/**isLoggedIn && '-'+profile.email+':'+profile.displayName*/}
              </Typography>
            </div>

            <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={3}
            >
          
 
              <Grid item>
                  <Hidden smDown>
                      <div className={classes.toolbar2}>
      
                        <Grid container  direction="row" justify="center" alignItems="center">
                          <Grid item>
                              <Button className={classes.menuButton2} onClick={()=>{handleShowHome(`?v1`)}}>Home</Button>
                          </Grid>
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleHelpPage}>Help</Button>
                          </Grid>
                          <Grid item>
                              <Button className={classes.menuButton2}  onClick={handleContactPage}>Contact Us</Button>
                          </Grid>
                          <Grid item>
                            <Button className={classes.loginButton} onClick={() => {   handleLogin(true); }}  >
                              Sign In
                            </Button>
                          </Grid> 
                          </Grid>
                         
                     </div>    
                  </Hidden>
                  <Hidden mdUp>
                  <IconButton onClick={handleClickMobile}>
                    <DotsVertical className={classes.dotsVerticalIcon} />
                  </IconButton>
                    
                    <Menu
                      id="simple-menu-mobile"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMobile}
                    >
                     
                       <Container>
                        <MenuItem onClick={()=>{handleShowHome(`/?fid=post&where=postType`)}}>Home</MenuItem>
                        <MenuItem onClick={handleHelpPage}>Help</MenuItem>
                        <MenuItem onClick={handleContactPage}>Contact Us</MenuItem>
                        <MenuItem onClick={() => {handleLogin(true);}}  >Sign In</MenuItem> 
                        </Container> 
         
                    </Menu>
                    
                  </Hidden>
                </Grid>
                 
                </Grid>
          </Toolbar>
        </AppBar>
          
        <Tooltip title="Create a new Post">
        <Fab size="large" aria-label="add" className={classes.fab} onClick={() => handleOpenPostDialog("layoutOpen")}>
            <AddIcon />
        </Fab> 
        </Tooltip>
   

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
           
            <Container  className={classes.headerSpacer2}>
              <List>
        
                  <ListItem button color="inherit" key='Welcome'  onClick={handleWelcomePage}>   
                    <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                      <ListItemText className={classes.linkButton} primary='Welcome' />
                      
                  </ListItem>
                
                </List>  
              </Container>
          
            <Divider />
          
       
            <Divider />
         
            <List> 
                <ListItem>
                <Grid  className={classes.fabMap2} container  >
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="BFQJ36PHWULPS" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_PH/i/scr/pixel.gif" width="1" height="1" />
                  </form>
                </Grid>
              </ListItem>
            </List>

             <Container>
               <Grid container  direction="column" justify="center"  alignItems="center">
                  <Grid item>
                    {process.env.GATSBY_ADDS_ENABLE === 'true' &&

                          <CardMedia  style={{ width:160, height: 620, border: 0, borderColor: "white" }} scrolling="no" component='iframe' title="Ads" 
                          src={`${process.env.GATSBY_STORAGE_URL}${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}/o/publix%2Fadds160x600.html?alt=media&token=091aaa65-5262-40ea-b685-6909263290a1`}></CardMedia>
                    }
                  
                  </Grid>
                </Grid>
              </Container>
           
          </div>
        </Drawer>
   
        <Hidden smDown>
           
            <main  className={clsx(classes.content, { [classes.contentShift]: open, })}>
              <div className={classes.headerSpacer} />
                  <SharedPosts parameterLocation={urlLocation}  />  
            </main>
    
        </Hidden>
        <Hidden mdUp>
           
            <main  className={clsx(classes.content2, { [classes.contentShift2]: open, })}>
              <div className={classes.headerSpacer} />        
                   <SharedPosts parameterLocation={urlLocation}  />  
            </main> 
        </Hidden>
  
     
        <Hidden smDown>
          <Drawer
              className={classes.drawerRight}
              variant="persistent"
              anchor="right"
              open={true}
              classes={{
                paper: classes.drawerPaperRight,
              }}
          > 
           <div className={classes.headerSpacer3}>
            
                 <Adds/>      
           </div>
          </Drawer>
        </Hidden>
      </div>
   
    );
}
 
 
 export default  SharedIndex  
 